import { css, cx } from "emotion"
import Img, { FluidObject } from "gatsby-image"
import React from "react"
import { ContactBlock, ContactBlockProps, PersonBlockProps, TeamBlock } from "@avonova-noa/components"
import { Breakpoints, Cell, colors, Grid, spacing, typography } from "@avonova-noa/common"
import SEO from "../components/seo"
import Layout from "./../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const paragraph = css`
    padding-top: ${spacing.xxl};
    padding-bottom: ${spacing.xxl};
    color:${colors.green.green11};

    a {
        color:${colors.green.green11};
    }

    .intro+& {
        padding-top: 0;
    }

    &:nth-child(2n) {
        background: ${colors.neutral.light2};
    }

    @media (${Breakpoints.mobile}){
        padding-top: ${spacing.ml};
        padding-bottom: ${spacing.ml};
    }
`

const fullWidthImage = css`
    left: 50%;
    max-width: 100vw;
    width: 100vw;
    overflow: hidden;
    position: relative;
    transform: translateX(-50%);
    margin-top: -${spacing.huge};
    margin-bottom: ${spacing.huge};

@media only screen and (min-width: 920px) {
  height: auto;
}

.gatsby-image-wrapper {
  height: 100%;
  @media only screen and (min-width: 920px) {
    height: auto;
  }
}
`

const widthWrapper = css`
    max-width: 670px;
    margin: 0 auto;
    padding: 0 20px;
    display: block;
`;

const header = css`
    ${typography.h2} 
    text-align:center; 
    margin-bottom: ${spacing.xl};
    @media (${Breakpoints.mobile}){
        margin-bottom: ${spacing.ml};
    }
`

const intro = css`
    ${typography.intro} 
    text-align:center;
    margin-bottom: ${spacing.xl};
    color: ${colors.green.green11};
    @media (${Breakpoints.mobile}){
        margin-bottom: ${spacing.ml};
    }
`

interface SimpleContentPageProps {
    pageContext: {
        header: string,
        heroImage: { fluid: FluidObject }
        intro: { intro: string }
        slug: string,
        content: {
            header: string,
            content: {
                json: {
                    content: {
                        content: {
                            value: string
                        }[]
                    }[]
                }
            }
        }[],
        team: {
            header: string,
            people: PersonBlockProps[]
        }
        contact: ContactBlockProps
    }
}

function SimpleContentPage({ pageContext }: SimpleContentPageProps) {
    // console.log(pageContext);
    return (
        <Layout>
            <SEO article={true} title={`${pageContext.header} | Helsehjulet`} description={pageContext.intro && pageContext.intro.intro} url={`https://helsehjulet.com/${pageContext.slug}`} />
            <div className={css`margin-top:${spacing.huge};`}>
                {pageContext.heroImage &&
                    <div
                        className={fullWidthImage}
                    >
                        <Img
                            alt={""}
                            fluid={pageContext.heroImage.fluid}
                        />
                    </div>
                }
                <h1 className={cx(widthWrapper, header)} >{pageContext.header}</h1>
                {pageContext.intro &&
                    <div className={cx(widthWrapper, intro, "intro")} >{pageContext.intro.intro}</div>
                }

                {pageContext.content &&
                    pageContext.content.map((item, index) => {
                        return (<div className={paragraph}
                            key={index}
                        >

                            <h2 className={cx(widthWrapper, typography.subtitle)}>{item.header}</h2>
                            {item.content && <div className={cx(widthWrapper, typography.bodySerif)}>{documentToReactComponents(item.content.json)}</div>}
                        </div>
                        )
                    }
                    )}{pageContext.team &&
                        <TeamBlock {...pageContext.team}></TeamBlock>
                }
                {pageContext.contact &&
                    <ContactBlock {...pageContext.contact}>
                    </ContactBlock>}
            </div>
            <div></div>
        </Layout>
    )
}

export default SimpleContentPage
